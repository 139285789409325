<template>
  <div class="user-drawer">
    <div class="left-panel">
      <div @click="checkNav(index)" v-for="(nav, index) in navList" :key="'nav-'+index" :class="['nav-item', checked === index ? 'checked': ''] ">
        <img v-if="checked === index" :src="require('@/assets/'+nav.icon+'_checked.svg')">
        <img v-else :src="require('@/assets/'+nav.icon+'.svg')">
        <span>{{ nav.label }}</span>
      </div>
    </div>
    <div class="right-panel">
      <UserBasic v-if="checked === 0"></UserBasic>
      <Business v-if="checked === 0"></Business>
      <Address v-if="checked === 1"></Address>
      <Invoice v-if="checked === 2"></Invoice>
    </div>
  </div>
</template>
<script>
import UserBasic from './components/userBasic.vue';
import Business from './components/business.vue';
import Address from './components/address.vue';
import Invoice from './components/invoice.vue';
export default {
  name: 'USER_INFO',
  components: {
    UserBasic,
    Business,
    Address,
    Invoice,
  },
  data () {
    return {
      checked: 0,
      navList: [
        { label: '个人信息', icon: 'ic_drawer_1' },
        { label: '收货地址', icon: 'ic_drawer_2' },
        { label: '开票信息', icon: 'ic_drawer_3' },
      ],
    };
  },
  methods: {
    checkNav (i) {
      this.checked = i;
    },
  },
};
</script>
<style lang="scss" scoped>
.user-drawer {
  display: flex;
  .left-panel {
    border-radius: 6px;
    background-color: #FFFFFF;
    height: calc(100vh - 140px);
    margin-right: 10px;
    padding: 8px;
    width: 234px;
    flex-shrink: 0;
    .nav-item {
      cursor: default;
      padding: 0 12px;
      margin-bottom: 10px;
      width: 100%;
      height: 40px;
      display: inline-flex;
      align-items: center;
      > img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      &:hover {
        background: #E2F3FE;
        border-radius: 4px;
      }
    }
    .checked {
      background: #E2F3FE;
      border-radius: 4px;
      color: #237FFA;
    }
  }
  .right-panel {
    width: 100%;
    max-height: calc(100vh - 140px);
    overflow-y: auto;
  }
}
</style>
