<template>
  <div class="business">
    <div class="title">关联企业</div>
    <div class="business-list">
      <div @click="scan(item.tenantId)" v-for="(item, index) in companyLists" :key="index" class="item">
        <div class="logo">
          <span>{{ item.tenantName ? item.tenantName.substr(0,2) : '' }}</span>
        </div>
        <div class="name-status">
          <span class="name text-ellipsis">{{ item.tenantName }}</span>
          <div :class="['status-box', item.authenticationStatus === '1' ? 'grey' : item.authenticationStatus === '2' ? 'orange' : 'green']">
            <img :src="findStatusImg(item)" />
            <span>{{ item.authenticationStatusDesc }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 企业资料 -->
    <el-dialog title="企业资料" :visible.sync="dialogVisible" width="700px">
      <div class="dislog-item width-50">
        <span class="label">企业名称</span>
        <span class="value">{{ currentRow.name || '--' }}</span>
      </div>
      <div class="dislog-item width-50">
        <span class="label">组织编号</span>
        <span class="value">{{ currentRow.code || '--' }}</span>
      </div>
      <div class="dislog-item width-50">
        <span class="label">经营类型</span>
        <span class="value">{{ currentRow.businessType | filterBusinessType }}</span>
      </div>
      <div class="dislog-item width-50">
        <span class="label">公司规模</span>
        <span class="value">{{ currentRow.scale || '--' }}</span>
      </div>
      <div class="dislog-item width-100">
        <span class="label">企业介绍</span>
        <span class="value">{{ currentRow.remark || '--' }}</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { BusinessTypeListEnum } from '@/enums/BusinessTypeListEnum';
import { companyDetail } from '@/api/company';
export default {
  name: 'BUSINESS',
  data () {
    return {
      statusList: [
        {
          status: '未认证',
          statusCode: '1',
          img: require('@/assets/ic_weirenzheng.svg'),
        },
        {
          status: '认证中',
          statusCode: '2',
          img: require('@/assets/ic_renzhengzhong.svg'),
        },
        {
          status: '已认证',
          statusCode: '3',
          img: require('@/assets/ic_yirenzheng.svg'),
        },
      ],
      currentRow: {},
      dialogVisible: false,
      companyLists: [],
    };
  },
  filters: {
    filterBusinessType (val) {
      let obj = BusinessTypeListEnum.find(item => item.type.toString() === val);
      return obj?.label || '--';
    },
  },
  methods: {
    findStatusImg (val) {
      const { img } = this.statusList.find(item => val.authenticationStatus === item.statusCode);
      return img;
    },
    async scan (id) {
      const { body } = await companyDetail({id});
      this.currentRow = body;
      this.dialogVisible = true;
    },
  },
  mounted () {
    const list = JSON.parse(this.$local.get('ownerList'));
    this.companyLists = list.filter(item => item.isPersonal === false);
  },
};
</script>
<style lang='scss' scoped>
.business {
  padding: 15px;
  border-radius: 6px;
  background-color: #FFFFFF;
  margin-bottom: 10px;
  .title {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #DFE2E8;
    font-weight: bold;
  }
  .business-list {
    display: flex;
    flex-wrap: wrap;
    .item {
      cursor: pointer;
      width: 320px;
      flex-shrink: 0;
      padding: 15px;
      margin-right: 10px;
      margin-bottom: 15px;
      border-radius: 6px;
      border: 1px solid #DFE2E8;
      display: inline-flex;
      align-items: center;
      .logo {
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        color: #237FFA;
        background-color: #E2F3FE;
        font-size: 16px;
        margin-right: 10px;
      }
      .name-status {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .name {
          width: 200px;
          font-size: 14px;
          font-weight: 600;
          color: #1F2733;
          margin-bottom: 6px;
        }
        .status-box {
          font-size: 12px;
          font-weight: 400;
          border-radius: 4px;
          padding: 2px 6px;
          display: inline-flex;
          align-items: center;
          > img {
            width: 12px;
            height: 12px;
            margin-right: 3px;
          }
        }
        .grey {
          background: #F2F4F7;
          color: #1F2733;
        }
        .orange {
          background: #FEFAE0;
          color: #FAB007;
        }
        .green {
          background: #E5FEEB;
          color: #38D677;
        }
      }
    }
  }
  .dislog-item {
    display: inline-flex;
    padding-right: 20px;
    padding-bottom: 20px;
    .label {
      color: #929AA6;
      padding-right: 15px;
      flex-shrink: 0;
    }
    .value {}
  }
  .width-50 {
    width: 50%;
  }
  .width-100 {
    width: 100%;
  }
}
</style>
