<template>
  <div class="address-form-box">
    <el-form
      :model="form"
      :rules="formRules"
      ref="form"
      style="width: 100%;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="收货人：" prop="name" :label-width="labelWidth">
            <el-input v-model.trim="form.name" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话：" prop="phoneNumber" :label-width="labelWidth">
            <el-input v-model.trim="form.phoneNumber" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="所在地区：" prop="contactAreaId" :label-width="labelWidth">
            <area-cascader
              :provinceId.sync="form.contactProvinceId"
              :provinceName.sync="form.contactProvinceName"
              :cityId.sync="form.contactCityId"
              :cityName.sync="form.contactCityName"
              :areaId.sync="form.contactAreaId"
              :areaName.sync="form.contactAreaName"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否设为默认：" prop="isDefault" :label-width="labelWidth">
          <el-radio-group v-model="form.isDefault">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        </el-col>
      </el-row>
      <el-row>
      <el-col :span="24">
        <el-form-item label="详细地址：" prop="contactAddrs" :label-width="labelWidth">
          <el-input v-model.trim="form.contactAddrs" placeholder="请输入"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    </el-form>
    <div class="button-group">
      <el-button @click="close" size="small">取消</el-button>
      <el-button @click="save" size="small" type="primary">保存</el-button>
    </div>
  </div>
</template>
<script>
import AreaCascader from '@/components/common/areaCascader';
import UserDrawer from '@/api/userDrawer';
export default {
  name: 'EDIT_ADDRESS',
  components: {
    AreaCascader,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler (param) {
        if (param) this.form = param;
      },
    },
  },
  data () {
    const checkPhoneNumber = (rule, value, cb) => {
      const regPhone = /^[1][0-9]{10}$/;
      if (value && !regPhone.test(value)) {
        cb(new Error('手机号格式有误'));
      }
      return cb();
    };
    return {
      labelWidth: '120px',
      form: {
        name: '',
        phoneNumber: '',
        contactProvinceId: '',
        contactProvinceName: '',
        contactCityId: '',
        contactCityName: '',
        contactAreaId: '',
        contactAreaName: '',
        isDefault: false,
        contactAddrs: '',
      },
      formRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phoneNumber: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: checkPhoneNumber, trigger: 'blur' },
        ],
        contactAreaId: [
          { required: true, message: '请选择所在地区', trigger: 'blur' },
        ],
        contactAddrs: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    close () {
      this.$emit('refresh', this.form.id);
    },
    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            await UserDrawer.updateAddress(this.form);
            this.$message({
              type: 'success',
              message: '更新成功!',
            });
            this.$emit('refresh');
          } else {
            await UserDrawer.addAddress(this.form);
            this.$message({
              type: 'success',
              message: '新增成功!',
            });
            this.$emit('refresh');
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.address-form-box {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #DFE2E8;
  margin-bottom: 20px;
  .button-group {
    padding-left: 20px;
    flex-shrink: 0;
  }
}
</style>
